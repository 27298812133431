import React, {Fragment} from 'react'
import PostList from "../features/post/PostList";
import {useTrends} from "../utilities/utils";
import {useDispatch, useSelector} from "react-redux";
import {getTrendingPosts} from "../screens/main/home/HomeActions";
import {initializeStore} from "../config/store";
import {NextSeo} from 'next-seo'
import {ListGroup} from "react-bootstrap";
import map from "lodash/map";
import Layout from "../features/nav/Layout";
import Image from "next/image";
import Link from "next/link";
import {Header, Segment} from "semantic-ui-react";
import {getTrendingYoutubeVideos} from "../utilities/youtube";

const HomePage = ({videosByCategory}) => {

    const description = "Keep up with Nigerian trends, breaking news, local news, fashion, weddings, viral video clips and funny jokes. NaijaWorld is open for everyone to post, share, vote and discuss";
    const metaImage = "/icons/icon-512x512.png";
    const metaUrl = "https://naijaworld.com";
    const title = "NaijaWorld | Building Nigeria's Best Online Forum";

    const {trends} = useTrends()

    const loading = useSelector((state) => state.async.loading)
    const posts = useSelector((state) => state.posts)
    const trendingPosts = Object.values(posts).sort((a, b) => b.hotScore - a.hotScore)
    const dispatch = useDispatch()

    const renderTrends = () => {
        return (
            <>
                <Segment
                    textAlign='center'
                    attached='top'
                    inverted
                    // color='teal'
                    className={'border-0'}
                    style={{backgroundColor: '#008B4B'}}
                >
                    <Header>Trends</Header>
                </Segment>
                <ListGroup>
                    {trends?.map(trend => (
                        <ListGroup.Item className={'p-2'} key={trend}>
                            <Link href={`/trend/${encodeURIComponent(trend)}`}>
                                <div className={
                                    'd-flex flex-row'
                                }>
                                    <h6 className={'ml-3 align-self-center text-left'}>{trend}</h6>
                                </div>
                            </Link>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </>
        )
    }

    return (
        <Fragment>
            <NextSeo
                title={title}
                description={description}
                canonical={metaUrl}
                openGraph={{
                    url: metaUrl,
                    title: title,
                    description: description,
                    images: [
                        {url: metaImage, alt: 'NaijaWorld'}
                    ],
                }}
            />
            <Layout mainContent={
                <div>
                    <div style={{height: 85}} className={"d-block w-100 p-1"}>
                        <div className={"d-flex flex-row overflow-auto"}>
                            {map(videosByCategory, (video) =>
                                <div key={video["categoryId"]}
                                     className={'d-flex flex-column text-center px-1 text-secondary'}>
                                    <Link href="/videos/[id]" as={`/videos/${video["categoryId"]}`}>
                                        <div className={"outer-circle rounded-circle"}>
                                            <Image
                                                src={video["thumbnail"]}
                                                className={'rounded-circle'}
                                                alt="thumbnail"
                                                height={56}
                                                width={56}
                                            />
                                        </div>
                                    </Link>
                                    <small>
                                        {video["categoryName"]}
                                    </small>
                                </div>)
                            }
                        </div>
                    </div>
                    <PostList
                        posts={trendingPosts}
                        getNextPosts={() => dispatch(getTrendingPosts(false))}
                        loading={loading}
                        hasMore={true}
                    />
                </div>
            } sidebarContent={
                <div className={'d-none d-lg-block p-3'}>
                    {renderTrends()}
                </div>
            }/>
        </Fragment>
    );
};

export async function getStaticProps() {

    const reduxStore = initializeStore()
    const {dispatch} = reduxStore

    await dispatch(getTrendingPosts(true))
    let {videosByCategory} = await getTrendingYoutubeVideos();

    return {
        props: {
            initialReduxState: reduxStore.getState(),
            videosByCategory
        },
        revalidate: 1800
    }

}

export default HomePage
